import React from 'react'
import { graphql } from 'gatsby'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import * as styles from './team.module.scss'
import { tr } from 'js/translations.js'
import { useLocalization } from 'gatsby-theme-i18n'

import Banner from 'components/Banner'
import Breadcrumb from 'components/Breadcrumb'
import ImageWrap from 'components/ImageWrap'
import IntroText from 'components/IntroText'
import Layout from 'components/Layout'
import Seo from 'components/Seo'
import TeamList from 'components/TeamList'

const Page = ({ data }) => {
  const { locale } = useLocalization()
  const page = data[`page_${locale}`].nodes[0]
  const breadcrumb = [
    {
      text: tr('HOME', locale),
      link: '/',
    },
    {
      text: page.title,
    },
  ]

  // let seoDescription = {
  //   en:
  //     'Led by Partners Mr. Chris Wang and Dr. Alex Tianli Zhang, the seasoned team of Hosen Capital has cumulated industry expertise and global vision.',
  //   zh:
  //     '厚生核心团队投资经验丰富，秉持深厚的实业情怀、行业经验和宽广的全球视野。',
  // }
  // seoDescription = seoDescription[locale]

  const introText = renderRichText(page.introText)
  return (
    <Layout>
      <div>
        <Seo
          pageTitle={page.title}
          // description={seoDescription}
        />
        <Banner image={page.banner} />
        <Breadcrumb items={breadcrumb} />
        <div className='wrapper pad-sides'>
          <div className='inner'>
            <IntroText content={introText} />
            <div className={styles.main}>
              <TeamList teamMembers={page.teamMembers} />
              <div className={styles.images}>
                {page.images.map((image, i) => (
                  <ImageWrap image={image} key={i} />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Page

export const pageQuery = graphql`
  query {
    page_en: allContentfulTeamPage(filter: { node_locale: { eq: "en-US" } }) {
      nodes {
        title
        banner {
          ...GatsbyImage
        }
        introText {
          raw
        }
        teamMembers {
          name
          jobTitle
          slug
        }
        images {
          ...GatsbyImage
        }
      }
    }
    page_zh: allContentfulTeamPage(filter: { node_locale: { eq: "zh-CN" } }) {
      nodes {
        title
        banner {
          ...GatsbyImage
        }
        introText {
          raw
        }
        teamMembers {
          name
          jobTitle
          slug
        }
        images {
          ...GatsbyImage
        }
      }
    }
  }
`
